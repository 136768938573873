import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/services';
import { tap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    Permissions: string[] = [];

    private isProduction = environment.production;
    private isLoggingEnabled = !this.isProduction;

    constructor(private apiService: ApiService) {}

    /**
     * Retrieves the permissions for the current client
     */
    getPermissions(): Observable<string[]> {
        return this.apiService.getPermissions().pipe(
            tap((permissions: string[]) => {
                this.Permissions = permissions;
            }),
        );
    }

    isViewer = (): boolean => {
        return true;
    };

    canModifyFields = (): boolean => {
        return (
            this.Permissions.includes('core.add_field') &&
            this.Permissions.includes('core.change_field') &&
            this.Permissions.includes('core.delete_field')
        );
    };

    canUpdateFieldsProperties = (): boolean => this.Permissions.includes('core.change_field');
}
